
import { Component, Prop, Vue } from "vue-property-decorator";
import { VMenuItem } from "vuelando";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
// import SearchModule from "@/store/modules/search.module";
import CartModule from "@/store/modules/cart.module";
import CancelTokenModule from "@/store/modules/cancelToken.module";

// const searchModule = getModule(SearchModule, store);
const cartModule = getModule(CartModule, store);
const cancelTokenModule = getModule(CancelTokenModule, store);

@Component
export default class ClientAppBar extends Vue {
  /** Sets the username */
  @Prop({ required: true, type: String })
  private username: string;
  /** Sets the user email */
  @Prop({ required: true, type: String })
  private email: string;
  /** Sets if the user is public */
  @Prop({ required: false, default: true, type: Boolean })
  private isPublic: boolean;
  /** Sets if the user is client */
  @Prop({ required: false, default: false, type: Boolean })
  private isClient: boolean;

  private get userMenuItems(): VMenuItem[] {
    let items = [];
    if (this.$route.name !== "user-profile") {
      items = [
        {
          label: "Dati personali",
          iconName: "user",
          action: () => {
            if (this.tabletAndDown) this.$router.push({ name: "personalinfo" });
            else this.$router.push({ name: "user-profile" });
          },
        },
        {
          label: "Preferiti",
          iconName: "heart",
          action: () => {
            if (this.tabletAndDown) this.$router.push({ name: "favorites" });
            else
              this.$router.push({
                name: "user-profile",
                params: { tab: "favorites" },
              });
          },
        },
        {
          label: "I tuoi ordini",
          iconName: "cost-note",
          action: () => {
            if (this.tabletAndDown) this.$router.push({ name: "myorders" });
            else
              this.$router.push({
                name: "user-profile",
                params: { tab: "orders" },
              });
          },
        },
      ];
    }
    items.push({
      label: "Logout",
      iconName: "log-out",
      action: () => {
        this.logout();
      },
    });
    return items;
  }

  private get tabletAndUp(): boolean {
    return this.$vuetify.breakpoint.smAndUp;
  }

  private get tabletAndDown(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  private get numberOfProducts(): number {
    return cartModule.products.length;
  }

  private get mobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  private goToHome(): void {
    this.$router.push({ name: "home" });
  }

  private goToLogin(): void {
    cancelTokenModule.empty();
    this.$router.push({ name: "login" });
  }

  private toggleCart(): void {
    cartModule.toggleCart();
  }

  private logout(): void {
    this.$emit("logout");
  }
}
