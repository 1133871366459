
import { Component, VModel, Vue } from "vue-property-decorator";

@Component
export default class ClientNavDrawer extends Vue {
  @VModel()
  private drawer: boolean;

  private navListItems = [
    {
      label: "Dati personali",
      iconName: "user",
      action: () => {
        if (this.$route.name !== "personalinfo")
          this.$router.push({ name: "personalinfo" });
        else this.close();
      },
    },
    {
      label: "Preferiti",
      iconName: "heart",
      action: () => {
        if (this.$route.name !== "favorites")
          this.$router.push({ name: "favorites" });
        else this.close();
      },
    },
    {
      label: "I tuoi ordini",
      iconName: "cost-note",
      action: () => {
        if (this.$route.name !== "myorders")
          this.$router.push({ name: "myorders" });
        else this.close();
      },
    },
  ];

  private close(): void {
    this.drawer = false;
  }
}
