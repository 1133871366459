
import { Component, Vue } from "vue-property-decorator";
import { authenticate } from "@/services/api/authenticate.service";
import { orders } from "@/services/api/orders.service";
import { productPhoto } from "@/services/api/productPhoto.service";
import { CartProduct } from "@/models/entities/cart-product.interface";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import CancelTokenModule from "@/store/modules/cancelToken.module";
import CartModule from "@/store/modules/cart.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { OrderRemove } from "@/models/entities/order-remove.interface";
const cancelTokenModule = getModule(CancelTokenModule, store);
const cartModule = getModule(CartModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class Cart extends Vue {
  private show = true;
  private orderToRemove = {} as OrderRemove;
  private photosDict = {} as { [id: string]: string };
  private photoProgress = false;
  private delivery = false;
  private radioGroup = 0;

  private get productsCount(): number {
    return cartModule.products.length;
  }

  private get products(): CartProduct[] {
    return cartModule.products;
  }

  private get totals(): CartProduct[] {
    return cartModule.totals;
  }

  private get cartTotal(): string {
    let total = 0;
    this.totals.forEach((product) => {
      if (product.isInPromo) {
        total += product.totalInPromo;
      } else {
        total += product.total;
      }
    });
    return total.toFixed(2);
  }

  private get smAndUp(): boolean {
    return this.$vuetify.breakpoint.smAndUp;
  }

  private get mobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  mounted(): void {
    this.getOrder();
    this.getDelivery();
  }

  private async getOrder(): Promise<void> {
    overlayModule.showOverlay();
    const r = await orders.getCart();
    cartModule.getCart(r.data);
    if (r.data.length) {
      this.photo(r.data);
    }
  }

  private async getDelivery(): Promise<void> {
    overlayModule.showOverlay();
    const d = await orders.getDelivery();
    this.delivery = d.data;
  }

  private async photo(products: CartProduct[]): Promise<void> {
    this.photoProgress = true;
    for (var el of products) {
      try {
        let photo = (
          await productPhoto.getPhotoByMeasureUnitTypeID(
            el.productPriceBookID,
            el.measureUnitTypeID
          )
        ).data;
        if (photo.byteLength == 0) {
          photo = (await productPhoto.getDefaultPhotoByProductId(el.productID))
            .data;
        }
        let img = window.URL.createObjectURL(
          new Blob([new File([photo], "photo")], { type: "image/png" })
        );
        this.$set(
          this.photosDict,
          `${el.productPriceBookID}_${el.measureUnitTypeID}`,
          img
        );
      } catch (error) {
        console.error;
      }
    }
    this.photoProgress = false;
  }

  async logout(): Promise<void> {
    overlayModule.showOverlay();
    await authenticate.logout();
    this.$router.push({ name: "Logout" });
  }

  private async onChangeOrder(product, removeProduct): Promise<void> {
    this.$set(
      this.orderToRemove,
      "productPriceBookID",
      product.productPriceBookID
    );
    this.$set(
      this.orderToRemove,
      "measureUnitTypeID",
      product.measureUnitTypeID
    );
    if (removeProduct) {
      this.$set(product, "quantity", 0);
      cartModule.delete(product);
    } else {
      cartModule.edit(product);
    }
    this.$set(
      this.orderToRemove,
      "newQuantity",
      product.quantity.toString().toNumber()
    );
    await orders.removeOrder(this.orderToRemove);
  }

  private async removeProducts(): Promise<void> {
    cancelTokenModule.empty();
    await orders.emptyCart();
    cartModule.empty();
  }

  private async sendOrder(): Promise<void> {
    overlayModule.showOverlay();
    cancelTokenModule.empty();
    const db = await orders.getCart();
    const current = cartModule.products;
    const dbCart = this.sortCart(db.data);
    const currentCart = this.sortCart(current);
    if (current.length > 0) {
      if (
        Object.entries(dbCart).toString() ===
        Object.entries(currentCart).toString()
      ) {
        overlayModule.showOverlay();
        await orders.sendCart(
          this.delivery ? (this.radioGroup == 0 ? true : false) : false
        );
        await this.getOrder();
        snackbarModule.showSnackbar({
          message: "Ordine inviato con successo",
          type: "success",
        });
        setTimeout(() => snackbarModule.hideSnackbar(), 2000);
        cartModule.changeOrderCompleted(true);
        cartModule.setShowCart();
      }
    } else {
      snackbarModule.showSnackbar({
        message: "Il carrello è vuoto",
        type: "error",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    }
  }

  private sortCart(array: CartProduct[]): CartProduct[] {
    return array
      .sort((a, b) => (a.productPriceBookID <= b.productPriceBookID ? 1 : -1))
      .sort((a, b) =>
        a.productPriceBookID == b.productPriceBookID &&
        a.measureUnitTypeID > b.measureUnitTypeID
          ? 1
          : -1
      );
  }

  private toggleCart(): void {
    cartModule.toggleCart();
  }
}
