
import { Component, Vue } from "vue-property-decorator";
import { authenticate } from "@/services/api/authenticate.service";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import CartModule from "@/store/modules/cart.module";
import OverlayModule from "@/store/modules/overlay.module";
import CancelTokenModule from "@/store/modules/cancelToken.module";
import Cart from "../Cart/Cart.vue";
import ClientAppBar from "./Client/ClientAppBar.vue";
import ClientNavDrawer from "./Client/ClientNavDrawer.vue";
import DefaultAppBar from "./Default/DefaultAppBar.vue";
import DefaultNavDrawer from "./Default/DefaultNavDrawer.vue";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const cartModule = getModule(CartModule, store);
const cancelTokenModule = getModule(CancelTokenModule, store);

import { CLIENT, PUBLIC } from "@/helpers/roles";

@Component({
  components: {
    Cart,
    ClientAppBar,
    ClientNavDrawer,
    DefaultAppBar,
    DefaultNavDrawer,
  },
})
export default class Layout extends Vue {
  private backOfficeDrawer = true;
  private clientDrawer = false;

  private get cart(): boolean {
    return cartModule.showCart;
  }

  private get userEmail(): string {
    return authModule.userEmail;
  }

  private get isClient(): boolean {
    if (authModule.role === CLIENT) return true;
    return false;
  }

  private get isPublic(): boolean {
    if (authModule == null || authModule.role == PUBLIC) return true;
    return false;
  }

  private get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  private get username(): string {
    let email = authModule.userEmail;
    if (email && email.length) {
      if (this.$vuetify.breakpoint.xs)
        return email.substring(0, 2).toUpperCase();
      return email.substring(0, email.indexOf("@")).toTitleCase();
    }
    return "--";
  }

  private async logout(): Promise<void> {
    overlayModule.showOverlay();
    cancelTokenModule.empty();
    cartModule.closeCart();
    await authenticate.logout();
    this.$router.push({ name: "Logout" });
  }

  private activateBackOfficeDrawer(): void {
    this.backOfficeDrawer = !this.backOfficeDrawer;
  }

  private activateClientDrawer(): void {
    this.clientDrawer = !this.clientDrawer;
  }
}
