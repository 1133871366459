
import { Component, VModel, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";

import { ADMIN, OPERATOR } from "@/helpers/roles";

const authModule = getModule(AuthModule, store);

@Component
export default class DefaultNavDrawer extends Vue {
  @VModel()
  private drawer: boolean;

  private menuItems: { [key: string]: string }[] = [
    {
      title: "Dashboard",
      icon: "mdi-chart-gantt",
      to: "/dashboard",
      group: "Dashboard",
      role: OPERATOR,
    },
    {
      title: "Fornitori",
      icon: "mdi-truck",
      to: "/suppliers",
      group: "Anagrafiche",
      role: ADMIN,
    },
    {
      title: "Clienti",
      icon: "mdi-account-tie",
      to: "/customers",
      group: "Anagrafiche",
      role: ADMIN,
    },
    {
      title: "Prodotti",
      icon: "mdi-basket",
      to: "/products",
      group: "Amministrazione",
      role: OPERATOR,
    },
    {
      title: "Listini",
      icon: "mdi-tag",
      to: "/product-price-books",
      group: "Amministrazione",
      role: OPERATOR,
    },
    {
      title: "Anteprima",
      icon: "mdi-eye",
      to: "/preview",
      group: "Amministrazione",
      role: OPERATOR,
    },
    {
      title: "Gestione ordini",
      icon: "mdi-clipboard-text",
      to: "/orders",
      group: "Gestione ordini",
      role: OPERATOR,
    },
    {
      title: "Tabelle",
      icon: "mdi-table",
      to: "/tables",
      group: "Tabelle",
      role: ADMIN,
    },
    {
      title: "Utenti",
      icon: "mdi-account-multiple",
      to: "/users",
      group: "Anagrafiche",
      role: ADMIN,
    },
  ];

  private get tabletAndDown(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  private get groups(): string[] {
    let items: { [key: string]: string }[] = null;
    if (authModule.role == OPERATOR)
      items = this.menuItems.filter((x) => x.role == OPERATOR);
    if (authModule.role == ADMIN)
      items = this.menuItems.filter(
        (x) => x.role == OPERATOR || x.role == ADMIN
      );

    this.menuItems = items;
    const uniqueGroups = items
      .map((el) => {
        return el.group;
      })
      .filter((value: string, index: number, self) => {
        return self.indexOf(value) === index;
      });
    return uniqueGroups;
  }

  private groupItems(group: string): { [key: string]: string }[] {
    let items = this.menuItems.filter((x) => x.group == group);
    return items;
  }
}
