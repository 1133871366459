
import { Component, Prop, Vue } from "vue-property-decorator";
import { VMenuItem } from "vuelando";

@Component
export default class DefaultAppBar extends Vue {
  /** Sets the username */
  @Prop({ required: true, type: String })
  private username: string;
  /** Sets the user email */
  @Prop({ required: true, type: String })
  private email: string;

  private userMenuItems: VMenuItem[] = [
    {
      label: "Logout",
      iconName: "log-out",
      action: () => {
        this.logout();
      },
    },
  ];

  private logout(): void {
    this.$emit("logout");
  }

  private drawer(): void {
    this.$emit("drawer");
  }
}
